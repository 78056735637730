import React, { useContext, useEffect, useState } from 'react';
import ScreenSizeContext from '../../contexts/ScreenSizeContext';
import './NoItemFound.scss';
import '../../common.scss';
import { Footer } from '../footer/Footer';
import ItemsContext from '../../contexts/ItemsContext';
import FiltersContext from '../../contexts/FiltersContext';
import { validateFiltersFromUrl } from '../../utils/functions';
import { BsFillFunnelFill, BsList } from 'react-icons/bs';
import { FcAdvance } from "react-icons/fc";

// This component is displayed when no results were received from the API call

export const searchPerformed = (): boolean => {
  console.log('url--' + window.location.href );
  return window.location.href.includes("pName=");
};

export const NoItemFound = () => {
  const searched = searchPerformed()
  const screenSizeContext = useContext(ScreenSizeContext);
  const itemsContext = useContext(ItemsContext);
  const filterContext = useContext(FiltersContext);
  const noResult = searched && !filterContext.loading && itemsContext.items.length <= 0;
  const [errors , setErrors] = useState<string[]>([]);

  useEffect(() => {
    const validationErrors = validateFiltersFromUrl();
    setErrors(validationErrors);
  }, [window.location.search]); // Re-run validation when URL parameters change

  return (
    <div className={`notfound-container ${itemsContext.items.length > 0 && 'd-none'}`}>
    {<p className={`no-result-found ${noResult ? 'd-block' : `d-none` }  `}>No Results Found</p>}
    {errors.length > 0 && errors.map((error , index) =>{
      return <p key={index} className={`no-result-found ${noResult ? 'd-block' : `d-none` }  `}>{error}</p>
    })}
    <div
      className={
        'no-item-found-container ' +
        (screenSizeContext.isScreenWidthExtraLarge
          ? 'xl-no-item-found-container'
          : screenSizeContext.isScreenWidthLarge
          ? 'lg-no-item-found-container'
          : 'small-no-item-found-container')
      }
    >
      <div id="ctasection"
        className={
          'no-item-found-content ' +
          (!screenSizeContext.isScreenWidthLarge ? 'small-font-size' : '')
        }
      >			
     {/*<div className="parentDisable">
     <div className="overlay-box">	  
        <Spinner animation="border" role="status" className="spinner">
           <span className="visually-hidden">Loading...</span>
        </Spinner>
       </div>
      </div> */}
      <div className="parent">
     { !searched&&<>
         <div className="first"></div>
         <div className="last">
            <h1>Find Your Perfect Laptop Across Top Retailers!</h1>
         </div>
         </> }
         <div className="top-text">
{!searched &&<>
{/* 			<div className="search-detail mt-3 mb-3">Advanced Search allows filter results by technical specs & price!</div> */}
{/* 			<div className="search-detail mb-3">Compare laptops&apos; specs side-by-side!</div> */}
{/* 			<div className="search-detail mb-3">Search by technical specs not found on all retailers&apos; websites!</div> */}
            <h2 className="mt-3">Why PriceWired?</h2>
            <ul>
                <li className="landing-listitem"><FcAdvance/> Search DELL, Walmart & Best Buy inventories simultaneously!</li>
                <li className="landing-listitem"><FcAdvance/> Standardized search filters across all retailers!</li>
                <li className="landing-listitem"><FcAdvance/> Side-by-side comparison of specs and prices!</li>
            </ul>
            <h2 className="mt-3">How it works?</h2>
            <ul>
                <li className="landing-listitem"><FcAdvance/> Choose technical specs on the left pane [click <BsFillFunnelFill/> on smaller screens]</li>
                <li className="landing-listitem"><FcAdvance/> Review results, adjust search criteria as needed or compare laptops.</li>
                <li className="landing-listitem"><FcAdvance/> Ready to buy? click on the laptop to buy on the retailers website.</li>
            </ul>
			</>}
          </div>
          <div>
            <img
                alt="Sleek laptop"
                src="images/laptop_home.png"
                className="d-inline-block align-top"/>
          </div>
        </div>

      </div>
      <Footer />
    </div>
    </div>
  );
};
