import React, { ReactNode, useEffect, useRef, useState } from "react";
import {
  formatValue,
  Item,
  openExternalLink,
  Price,
} from "../item-card/ItemCard";
import { Image, Table } from "react-bootstrap";
import ExpandableText from "../expandable-text/ExpandableText";
import "./CompareTable.scss";
import { RetailerLogo, RetailerLogosName } from "../retailer-logo/RetailerLogo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";


interface ProductDetails {
  screenResolution: string;
  screenSize: string;
  ramSize: string;
  storageSize: string;
  cpuBrand: string;
  cpuSpeed: string;
  cpuCores: number;
  gpuBrand: string;
  osName: string;
  supportedWireless: string[];
  usb3Ports: number;
  usb2Ports: number;
  usbTypeCPorts: number;
  thunderbolt4Ports: number;
  thunderbolt3Ports: number;
  bluetooth: boolean;
  websiteCode: string;
}

const specsArray = [
  "websiteCode",
  "screenResolution",
  "screenSize",
  "ramSize",
  "storageSize",
  "cpuBrand",
  "cpuSpeed",
  "cpuCores",
  "gpuBrand",
  "osName",
  "supportedWireless",
  "usb3Ports",
  "usb2Ports",
  "usbTypeCPorts",
  "thunderbolt4Ports",
  "thunderbolt3Ports",
  "bluetooth",
];
const specsLabels: { [key: string]: string } = {
  websiteCode: "Retailer",
  screenResolution: "Screen Resolution",
  screenSize: "Display Size",
  ramSize: "Ram Size (GB)",
  storageSize: "Storage Size (GB)",
  cpuBrand: "CPU Brand",
  cpuSpeed: "CPU Speed (GHz)",
  cpuCores: "CPU Cores",
  gpuBrand: "GPU Brand",
  osName: "Operating System",
  supportedWireless: "Supported Wireless",
  usb3Ports: "USB 3 Ports",
  usb2Ports: "USB 2 Ports",
  usbTypeCPorts: "USB Type-C Ports",
  thunderbolt4Ports: "Thunderbolt 4 Ports",
  thunderbolt3Ports: "Thunderbolt 3 Ports",
  bluetooth: "Bluetooth",
};

type nullSpecArray = {
  [key:string] : number
}
const CustomTable = ({ compareItemArray }: { compareItemArray: Item[] }) => {
  const [hideUnavailableSpec , setHideUnavailable] = useState(true);
  const [nullSpecArray , setNullSpecArray] = useState<nullSpecArray>({});
  const theadRef = useRef<any>(null);
  const tbodyRef = useRef<any>(null);
  const printRef = useRef<any>(null);

  const scrollHanler = (location: string) => {
    const thead = theadRef.current;
    const tbody = tbodyRef.current;

    if (location == "thead") {
      tbody.scrollLeft = thead.scrollLeft;
    } else {
      thead.scrollLeft = tbody.scrollLeft;
    }
  };
  const nullSpecCounter = () =>{
    const tempObj:nullSpecArray = {};
    
    specsArray.map((specs) => {
      compareItemArray.map((product) =>{
        const productValue = product?.details?.[specs as keyof ProductDetails];
        if(!productValue){

          if(tempObj[specs]){
            tempObj[specs]++;
          }else{
            tempObj[specs] = 1;
          }
        }
      })
    })
    setNullSpecArray(tempObj)
  }
  useEffect(() =>{
    nullSpecCounter();
  },[])

  const handlePrint = () => {
    // Add a class to the print section to apply print-specific styles
    printRef.current.classList.add('print-area');
    
    // Print the content within the printRef
    window.print();
    
    // Remove the class after printing
    printRef.current.classList.remove('print-area');
  };

  return (
    <div className="mb-auto mt-3" ref={printRef}>
      <div className="print-logo d-none">
        <img
          alt=""
          src="images/pwlogo_blue.png"
          className="m-auto my-4"
        />
      </div>
      <Table className="custome_table w-100 d-flex flex-column varient-2 ">
        <thead className="d-flex flex-column bg-white sticky-element w-100 ">
          <tr
            onScroll={() => scrollHanler("thead")}
            ref={theadRef}
            className="d-flex w-100 overflow-auto"
          >
            {compareItemArray.map((product, index) => {
              return (
                <th
                  className="product d-flex flex-column gap-3 p-3 bg-white"
                  key={product.sku + index}
                >
                  <div
                    className="product-image mx-auto line-clamp"
                    onClick={() => openExternalLink(product.merchantUrl)}
                  >
                    <Image className="pwlink" src={product?.details?.imageURL} />
                  </div>
                  <div className="product-price text-start pwlink">
                    <Price
                      regularPrice={
                        product?.details?.regularPrice?.toString() ?? ""
                      }
                      salePrice={product?.details?.salePrice?.toString() ?? ""}
                      onClick={() => openExternalLink(product.merchantUrl)}
                    />
                  </div>
                  <div
                    className="product-name text-center "
                    onClick={() => openExternalLink(product.merchantUrl)}
                  >
                    <ExpandableText
                      classes="fw-bold pwlink"
                      text={product?.details?.name ?? ""}
                    />
                  </div>
                </th>
              );
            })}
          </tr>
          <tr className="w-100 d-flex border-none justify-content-between px-4 shadow-sm">
            <td className="d-flex align-items-center gap-2  py-2 border-0">
              <input
                type="checkbox"
                id="hiderows"
                checked={hideUnavailableSpec}
                onChange={() => setHideUnavailable((prev) => !prev)}
              />
              <label
                className="cursor-pointer user-select-none"
                htmlFor="hiderows"
              >
                Hide rows with no values
              </label>
            </td>
            <td
              className="d-flex gap-2 align-items-center cursor-pointer cursor-pointer border-0"
              onClick={() => handlePrint()}
            >
              <span>Print Result</span>
              <FontAwesomeIcon icon={faPrint} />
            </td>
          </tr>
        </thead>
        <tbody
          onScroll={() => scrollHanler("tbody")}
          ref={tbodyRef}
          className="overflow-auto "
        >
          {specsArray.map((specs, index) => {
            if (
              hideUnavailableSpec &&
              nullSpecArray[specs] === compareItemArray.length
            ) {
              return;
            }

            return (
              <tr className="d-flex flex-column position-relative" key={index}>
                <td className="d-flex align-items-center gap-2 fw-semibold bg-light py-2 px-4">
                  <label>
                    {specs === "websiteCode" ? "Retailer" : specsLabels[specs]}
                  </label>
                </td>
                <td className="w-100 d-flex bg-white border-0 py-2 px-4">
                  {compareItemArray.map((product, index) => {
                    const productValue =
                      product?.details?.[specs as keyof ProductDetails];
                    return (
                      <td className="product_td text-center my-1" key={index}>
                        {specs === "websiteCode" ? (
                          <div className="pwlink"
                            onClick={() =>
                              openExternalLink(product.merchantUrl)
                            }
                          >
                            <RetailerLogo
                              websiteCode={
                                product?.details
                                  ?.websiteCode as RetailerLogosName
                              }
                            />
                          </div>
                        ) : (
                          formatValue(productValue)
                        )}
                      </td>
                    );
                  })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default CustomTable;
